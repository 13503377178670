.list>input[type="checkbox"]+label {
    display: block;
    cursor: pointer;
}

.list>input[type="checkbox"] {
    display: none;
}

.list>input[type="checkbox"]+label:before {
    content: " ";
    background: url(../../assets/images/check.svg) no-repeat center center;
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    padding-left: 0.1em;
    margin-right: 1rem;
    vertical-align: middle;
    color: transparent;
    transition: 0.2s;
}

.list>input[type="checkbox"]:checked+label:before {
    background-color: #FC5719;
    border-color: #FC5719;
    color: #fff;
}