/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";;
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/repeater";
@import "custom/plugins/form-editors";
@import "custom/plugins/xeditable";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/price";

@import 'react-phone-number-input/style.css';

.cursor-pointer {
    cursor: pointer;
}
.mr-10 {
    margin-right: 10px;
}
.mr-5 {
    margin-right: 5px;
}
.eye-icon {
    position: absolute;
    top: 32px;
    right: 10px;
    bottom: 0;
    font-size: 22px;
}
.react-bootstrap-table-pagination #pageDropDown {
    color: #fff!important;
    background-color: $primary;
    border-color: $primary;

    &:focus {
        border-color: $primary;
    }
}
.add-secondary-user{
    position: absolute !important;
    right: 0;
    top: -10px;
    margin-bottom: 5px;
}
.dashboard-select {
    width: 200px;
    margin-left: 10px;
}
.iframe{
	display: none !important;
    z-index: -1 !important;
}

.fetchingData{
    display: flex;
    justify-content: center;
    font-size: 2rem;
    align-items: center;
    height: 80vh;
}
.registration-box {
    max-width: 545px;
    margin: auto;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 40px;
    // box-shadow: 0 2px 4px rgba(15 ,34, 58, 20%);
}