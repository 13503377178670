/*  */
.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg);
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.svg-pi-label__progress {
  font-weight: 600;
  font-size: 16px;
  line-height: 24.2px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Custom styles for the calendar dropdown */
.calendar-input ::-webkit-datetime-edit,
.calendar-input ::-webkit-datetime-edit-fields-wrapper,
.calendar-input ::-webkit-datetime-edit-text,
.calendar-input ::-webkit-datetime-edit-month-field,
.calendar-input ::-webkit-datetime-edit-day-field,
.calendar-input ::-webkit-datetime-edit-year-field,
.calendar-input ::-webkit-inner-spin-button,
.calendar-input ::-webkit-calendar-picker-indicator {
  color: #4CAF50;
  background-color: #e8f5e9;
  /* light green background */
}

.calendar-input ::-webkit-calendar-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(90deg);
}